.breadcrumb-site {
	padding-top: 2px;
	padding-bottom: 2px;

	.breadcrumb-item + .breadcrumb-item::before {
		content: "\f054";
		font-family: "Font Awesome 5 Pro";
		padding: 0 10px;
		font-size: 12px;
		color: $primary;

	}
}