.footer {
	.list-unstyled li a {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -1px;
			height: 1px;
			background-color: $primary;
			transition: width 0.5s;
			width: 0;
		}

		&:hover {
			&::before {
				width: 100%;
			}
		}
	}
}

.icon-payment {
	li svg {
		display: inline-block;
		width: 38px;
		height: 24px;
	}
}