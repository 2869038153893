.breadcrumb-custom {
	color: #959595;
	letter-spacing: 0;
	text-transform: capitalize;

	a {
		color: inherit;
		text-decoration: none;
	}



	.breadcrumb-item {
		display: flex;
		align-items: center;
		&:hover {
			color: $breadcrumb-active-color;
			text-decoration: none;
		}
	}

	.breadcrumb-item + .breadcrumb-item::before {
		content: '';
		width: 10px;
		height: 1px;
		background-color: #B2B2B2;
		margin-right: 10px;
	}
}

