.mfp-search-form {
	.mfp-content {
		background-color: $white;
	}

	.mfp-container {
		padding: 0;
	}


	.mfp-content {
		vertical-align: top;
	}

	.search-popup {
		margin: 0 auto;
		padding: 32px 0;
	}

	input::placeholder {
		color: $text-muted;
	}

	button.dropdown-toggle {
		&:focus-visible, &:focus {
			outline: none !important;
		}

	}

	li.active .dropdown-item,
	.dropdown-item:hover,
	.dropdown-item:focus {
		color: #fff;
		background-color: $primary;
	}

	.mfp-close {
		display: none;
	}
}

.mfp-move-from-top {

	/* start state */
	.mfp-content {
		opacity: 0;
		transition: all 0.5s;
		transform: translateY(-100px);
	}

	.mfp-close,
	&.mfp-bg {
		opacity: 0;
		transition: all 0.5s;
		transition-delay: 0s;
	}

	/* animate in */
	&.mfp-ready {
		.mfp-content {
			opacity: 1;
			transform: translateY(0);
			transition-delay: 0.5s;
		}

		&.mfp-bg {
			opacity: 0.95;
		}

		&.gsf-menu-popup {
			opacity: 1;
		}

		.mfp-close {
			opacity: 1;
			font-weight: 400;
		}
	}

	/* animate out */
	&.mfp-removing {

		.mfp-content {
			transform: translateY(-50px);
			opacity: 0;
			transition-delay: 0s;
		}

		.mfp-close,
		&.mfp-bg {
			opacity: 0;
			transition-delay: 0.5s;
		}

	}

}

.card-additional-form {
	//display: none;
	background: #fff;
	bottom: 0;
	box-shadow: 20px 0 20px #0000001f;
	left: 0;
	padding: 1.5rem 1rem 4rem;
	position: absolute;
	transform: translateY(100%);
	transition: transform .25s ease-in-out;
	width: 100%;
	z-index: 100;

	&.show {
		transform: translateY(0);
	}

	.btn-cancel {
		cursor: pointer;
		position: relative;
		z-index: 1000;
		transition: .25s ease-in-out;

		&:hover {
			text-decoration: underline;
			transform: translateY(-2px);
		}
	}
}

.button-note::after {
	background: #e5e7eb;
	content: "";
	display: block;
	height: 26px;
	position: absolute;
	right: 0;
	top: 11px;
	width: 1px;
}