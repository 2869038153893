.badge{
	line-height: 17px;
	text-transform: uppercase;
}
.badge-purple {
	background-color: #9A84C8;
	color: #fff;
}
.badge-pink {
	background-color: #F8796E;
	color: #fff;
}
.badge-green {
	background-color: #98D8CA;
	color: #fff;
}
.badge-soft-success {
	color: #34c38f;
	background-color: rgba(52, 195, 143, 0.18);
}
.badge-soft-danger {
	color: #f46a6a;
	background-color: rgba(244, 106, 106, 0.18);
}
.badge-soft-warning {
	color: #f1b44c;
	background-color: rgba(241, 180, 76, 0.18);
}

