.nav-custom {
	.nav-link {
		transition: all .3s;
		position: relative;

		&:hover, &.active {
			color: $primary;
		}

		&::after {
			background-color: currentColor;
			bottom: -2px;
			content: "";
			display: block;
			height: 2px;
			left: 0;
			position: absolute;
			transition: width .25s ease-in-out;
			width: 0;
		}

		&.active {
			&::after {
				width: 100%;
			}
		}
	}


}