.countdown-02 {
	.countdown-item {
		background: #FDEFEE;
		border: 1px solid #F8CCCC;
		border-radius: 5px;
		width: 80px;
		height: 80px;
		display: flex;
		flex-flow: column;;
		align-items: center;
		justify-content: center;
	}
}