.topbar {
	line-height: 40px;
	min-height: 40px
}

.topbar-border {
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.navbar > .container, .navbar > .container-fluid,
.navbar > .container-lg, .navbar > .container-md,
.navbar > .container-sm, .navbar > .container-xl, .navbar > .container-xxl {
	padding-left: ($grid-gutter-width/2) !important;
	padding-right: ($grid-gutter-width/2) !important;
}

.sticky-area {
	transition: all 0.5s;
	height: auto;
	background-color: transparent;
}

.sticky-area-wrap {
	position: relative;
	transition: all 0.5s;
	z-index: 1000;
}

.sticky-area {
	width: 100%;
}

.sticky .sticky-area {

	z-index: 1050;

}

.header-hidden {
	transform: translate(0, -100%);
	box-shadow: none !important;
}

.navbar-light {
	.sticky .sticky-area {
		box-shadow: $box-shadow;
		background-color: $white;
	}
}

.navbar-dark {
	.nav-search {
		color: $white !important;
	}

	.nav-link, .dropdown-header {
		&::before {
			background-color: $white !important;
		}
	}
}

.header-above {
	.navbar-nav {
		svg {
			font-size: 28px;
		}
	}
}


.main-header {
	.dropdown-menu {
		position: absolute;
		border: none;
		border-radius: 0;
		margin-top: 0;
		z-index: 9999;
	}


	.sticky-logo, .sticky-button-icon {
		display: none;
	}

	.navbar-brand {
		max-width: 179px;
	}

	.normal-logo, .normal-button-icon {
		display: inline-block;
	}

	.navbar .menu-bold {
		.nav-link {
			font-size: 15px;
			font-weight: bold;
			letter-spacing: 0;
		}
	}

	.navbar {
		padding-top: 0;
		padding-bottom: 0;
		position: static;

		.nav-link {
			display: inline-block;
			font-size: 16px;
			font-weight: 500;
			text-transform: capitalize;

			svg {
				font-size: 28px;
			}
		}

		.nav-link:focus {
			outline: none;
		}

		.dropdown-link {
			color: $body-color;

			&:hover {
				text-decoration: none;
				border-bottom: 2px solid $primary;
			}
		}

		.dropdown-link:focus, .dropdown-item:focus, .dropdown-link:hover,
		.dropdown-item:hover, .dropdown-item.active, .dropdown-item.active .dropdown-link {
			color: $primary;
			outline: none;
			background-color: transparent;
		}

		.dropdown-menu {
			box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);

			.dropdown-toggle::after {
				content: '\f054';
			}
		}

	}

	.nav-search {
		display: flex;
		align-items: center;

		i {
			font-size: 18px;
		}
	}

	.main-menu {
		.nav-link, .dropdown-header {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				left: 0;
				bottom: -2px;
				height: 2px;
				background-color: $primary;
				transition: width 0.5s;
			}
		}

		.nav-item {
			transition: all 0.5s;

			.nav-link::before {
				width: 0;
			}

			&:hover {
				.nav-link::before {
					width: 100%;
				}
			}
		}
	}

	.dropright .dropdown-menu {
		margin-left: 0;
	}

	.dropdown-item-shop {
		position: static;
	}

	.dropdown-menu-listing {
		left: 0;
		width: 100%;

		.dropdown-item, .dropdown-header {
			padding-left: 0;
		}
	}

	.divider {
		content: '';
		width: 1px;
		height: 15px;
		opacity: 0.7;
		background-color: #fff;
	}

	.dropdown-menu-docs {
		min-width: 250px;

		i, small {
			color: $text-muted;
		}
	}

	@include media-breakpoint-down(lg) {
		//position: static !important;
		.navbar-collapse {
			background-color: $white;
		}
		.sticky {
			.sticky-area {
				background-color: $white !important;
			}

			.nav-search {
				color: $primary !important;
			}

			.main-menu {
				max-height: 500px;
				overflow: auto;
				width: 100%;

				.dropdown-menu {
					position: static;
					box-shadow: none;
					padding-top: 0;
					background-color: transparent;

					.dropdown-link, .dropdown-item {
						color: $primary;
					}

					.dropdown-header {
						color: $primary !important;
					}

					.dropdown-item.dropdown {
						&:hover, &:focus, &.active {
							background-color: transparent;
						}
					}
				}

				.dropright .dropdown-toggle {
					display: inline-block;

				}

			}

			.navbar-nav > .nav-item > .nav-link, .navbar-toggler {
				color: $primary;
			}
		}

	}

	.navbar-right {
		.nav-link:hover {
			text-decoration: none;
		}

		.nav-link i {
			font-size: 20px;
		}

		.number {
			top: -10px;
			right: 2px;
			font-size: 12px;
			background-color: $secondary;
			height: 22px;
			width: 22px;
			color: $white;
			line-height: 22px;
			border-radius: 50%;
			text-align: center;
		}
	}
}


.sticky {
	.sticky-logo, .sticky-button-icon {
		display: inline-block;
	}

	.normal-logo, .normal-button-icon {
		display: none;
	}

}

.navbar-light-sticky {
	.nav-search {
		color: $primary !important;
	}
}

.header-02 .main-menu .nav-item {
	padding-top: 28px !important;
	padding-bottom: 28px !important;
}

.header-03 .main-menu .nav-item {
	padding-top: 16px !important;
	padding-bottom: 22px !important;
}

.header-04 .main-menu .nav-item {
	padding-bottom: 43px !important;
	padding-top: 43px !important;
}

.header-01 .main-menu .nav-item {
	padding-top: 28px !important;
	padding-bottom: 38px !important;
}

.navbar-toggler, .toggle-bar {
	width: 28px;
	height: 28px;
	display: inline-block;
	position: relative;
	vertical-align: middle;
}

.toggle-icon {
	bottom: 0;
	display: block;
	height: 2px;
	left: 2px;
	right: 0;
	margin: auto;
	position: absolute;
	top: 0;
	transition: all 0.3s;
	background-color: currentColor;

	&::before, &::after {
		content: "";
		height: 2px;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		transition: top 0.3s ease-in-out, transform 0.3s ease-in-out, background 0.3s;
		transform-origin: 50% 50%;
		background-color: currentColor;
	}

	&::before {
		width: 18px;
		left: -6px;
		top: 8px;
	}

	&::after {
		top: -8px;
		left: -9px;
		width: 15px;
	}
}

.header-08 {
	@include media-breakpoint-up(xl) {
		.navbar-nav {
			flex-flow: row;
		}
		.navbar-right {
			padding-bottom: 0 !important;
			padding-top: 0 !important;

			.nav-item, .nav-link {
				padding-bottom: 0 !important;
				padding-top: 0 !important;
			}

			.number {
				top: -10px;
			}
		}
	}

	.dropdown-menu {
		margin-top: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		z-index: 9999;
	}

	@include media-breakpoint-down(lg) {
		.dropdown-menu {
			position: static !important;
			box-shadow: none;
			padding-top: 0;
			transform: translate3d(0, 0, 0) !important;
		}
	}


	.nav-item {
		padding-top: 9px !important;
		padding-bottom: 9px !important;
	}


}

.header-landing {
	.nav-link.active::before {
		width: 100% !important;
	}

}

.menu-image {
	min-width: 574px;
}



