.galleries {
	.slider-nav .slick-current img {
		border: 1px solid $primary;
	}
}

.shop-swatch {
	.selected .swatches-item {
		border-color: $primary !important;
	}
}

.shop-swatch-color {
	.swatches-item {
		position: relative;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
		transition: box-shadow .25s ease, transform .25s ease;
		font-size: 0;

		&::before {
			position: absolute;
			content: "";
			width: 24px;
			height: 24px;
			top: -7px;
			left: -6px;
		}
	}

	.list-inline-item {
		margin-right: 10px;

		&.selected, &:hover {

			.swatches-item {
				box-shadow: 0 0 0 1px #000, inset 0 0 0 4px #fff;
			}
		}
	}
}

.shop-swatch-color-02 {
	.swatches-item {
		width: 18px;
		height: 18px;
		&::before {
			display: none;
		}
	}

	.list-inline-item {
		margin-right: 2px;
		border-radius: 50%;
		border: 1px solid transparent;
		transition: all 0.2s;
		padding: 4px;
		&.selected, &:hover {
			border-color: #000;
			.swatches-item {
				box-shadow: none;
			}
		}
	}
}

.shop-swatch-color-03 {
	.swatches-item {
		width: 12px;
		height: 12px;
		border-radius: 50%;
	}

	.list-inline-item {
		margin: 0;
		border-radius: 50%;
		border: 1px solid transparent;
		transition: all 0.2s;
		padding: 4px;
		&.selected, &:hover {
			border-color: #ccc;
		}
	}
}

.product-image-dots {
	position: fixed;
	transition: opacity 0.2s;
	opacity: 1;
	@include media-breakpoint-down(md) {
		display: none !important;
	}
}

.product-images {
	padding-right: 100px;
	@include media-breakpoint-down(lg) {
		padding-right: 0;
	}
	@include media-breakpoint-down(md) {
		flex-wrap: wrap;
		padding-right: 0;
	}


	.slider-nav {
		flex: 0 0 70px;
		max-width: 70px;
		margin: 0;
		order: 1;

		.slick-current img {

			border: 1px solid $primary;

		}

		@include media-breakpoint-down(md) {
			flex: 1 1 100%;
			max-width: 100%;
			order: 2;
			padding-top: 10px;
		}
	}

	.slider-for {
		flex: 1 1 auto;
		order: 2;

		@include media-breakpoint-down(md) {
			flex: 1 1 100%;
			max-width: 100%;
			order: 1;
		}
	}
}

.custom-gallery {
	@include media-breakpoint-down(md) {
		flex-wrap: wrap;
		padding-right: 0;
	}


	.galleries-nav {
		flex: 0 0 90px;
		max-width: 90px;
		margin: 0;
		order: 1;

		@include media-breakpoint-down(md) {
			flex: 1 1 100%;
			max-width: 100%;
			order: 2;
			padding-top: 10px;
		}
	}

	.galleries-for {
		flex: 1 1 auto;
		order: 2;

		@include media-breakpoint-down(md) {
			flex: 1 1 100%;
			max-width: 100%;
			order: 1;
		}
	}
}


.frequently-bought-together {
	.custom-control-label::before {
		left: auto;
		right: 25px;
		top: 20px;
	}

	.custom-control-input:checked ~ .custom-control-label::before {
		content: "\f00c";
		font-family: "Font Awesome 5 Pro";
		font-size: 9px;
		text-align: center;
	}
}

.banner-product-page-12 .card-img {
	height: 740px;
	@include media-breakpoint-down(sm) {
		height: 400px;
	}
}