
.slider-home-01 {
	.vh-100-custom {
		height: calc(100vh - 85px);
	}

	@include media-breakpoint-up(lg) {
		.vh-100-custom {
			height: calc(100vh - 155px);
		}
	}

}

.slider-home-03, .slider-home-05 {
	.vh-100-custom {
		height: calc(100vh - 66px);
	}

	@include media-breakpoint-up(lg) {
		.vh-100-custom {
			height: calc(100vh - 116px);
		}
	}

}

.slider-home-06 {
	.vh-100-custom {
		height: calc(100vh - 95px);
	}

	@include media-breakpoint-up(lg) {
		.vh-100-custom {
			height: calc(100vh - 163px);
		}
	}

}

.slider-home-08 {
	.vh-100-custom {
		height: calc(100vh - 66px);
	}

	@include media-breakpoint-up(lg) {
		.vh-100-custom {
			height: calc(100vh - 40px);
		}
	}

}

.btn-link-custom {
	border-bottom-color: currentColor;
	border-radius: 0;
	border-width: 0;
	padding: 0.25rem 0;
	position: relative;

	&::before, &::after {
		background: currentColor;
		bottom: 0;
		content: "";
		height: 2px;
		left: 0;
		position: absolute;
		transform: scaleX(1) translateZ(0);
		transform-origin: right;
		transition: transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
		width: 100%;
	}

	&::after {
		transform: scaleX(0) translateZ(0);
		transform-origin: left;
		transition: transform .6s cubic-bezier(.165, .84, .44, 1);
	}

	&:hover {
		&::before {
			transform: scaleX(0) translateZ(0);
			transition: transform .6s cubic-bezier(.165, .84, .44, 1);
		}

		&::after {
			transform: scaleX(1) translateZ(0);
			transition: transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
		}
	}

	&.btn-link-custom-02 {
		&::before {
			background-color: #eee;
		}
	}
	&.btn-link-custom-03 {
		&::before {
			background-color: $primary;
		}
	}
}