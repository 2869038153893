.accordion {
	.card {
		overflow: visible;
	}

	.card-header {
		a {
			border-bottom: 1px solid $primary !important;
		}

		.icon {
			font-weight: 400;

			&:before {
				content: '\f106';
				color: $primary;
				font-family: "Font Awesome 5 Pro";
				font-size: 16px;
				transition: all 0.2s;
			}
		}


		a.collapsed {
			border-color: $border-color !important;

			.icon {
				&:before {
					content: '\f107';
				}
			}
		}
	}

}

.accordion-style-02 {

	.card-header {
		a {
			border-color: $primary !important;
		}

		.icon {
			&:before {
				content: '\f068';
				font-size: 12px;
			}
		}

	}

	.card-header a.collapsed {
		.icon {
			&:before {
				content: '\f067';
			}
		}
	}
}