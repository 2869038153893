.landing-page {
	font-family: $font-family-custom;
}

.badge-landing {
	background-color: rgba(0, 0, 0, 0.2);
	text-transform: none;
	line-height: 21px;

}

.high-light {
	display: inline-block;
	position: relative;
	font-weight: 500;
	color: $accent;

	&::after {
		content: '';
		position: absolute;
		left: 1px;
		bottom: -2px;
		height: 23px;
		width: 100%;
		background-color: #E5F5F1;
		z-index: -1;
	}
}

.fancy-text-slider-top {
	overflow: hidden;

	.high-light {
		opacity: 0;
	}

	.fancy-text-show {
		opacity: 1;
		-moz-animation: slider-top-in 0.6s;
		animation: slider-top-in 0.6s;
	}

	.fancy-text-hidden {
		opacity: 0;
		animation: slider-top-out 0.6s;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
	}
}


@keyframes slider-top-in {
	0% {
		transform: translateY(100%);
	}
	60% {
		transform: translateY(-10%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes slider-top-out {
	0% {
		transform: translateY(0);
	}
	60% {
		transform: translateY(-110%);
	}
	100% {
		transform: translateY(-100%);
	}
}

.title-number sup {
	top: 22px;
	right: -20px;
}

.shop-item {
	transform: translateY(0);
	transition: all 0.5s;

	.shop-img {
		filter: drop-shadow(2px 7px 10px rgba(0, 0, 0, 0.03));
		transition: all 0.5s;
	}

	&:hover {
		transform: translateY(-20px);
	}

	.shop-img {
		filter: drop-shadow(2px 7px 21px rgba(0, 0, 0, 0.03));
	}
}

.features {
	.feature-item .card {
		max-width: 90px;
	}
}

.section-single-product-layout .title {
	@include media-breakpoint-up(xl) {
		padding: 220px 0;
	}
}

.image-absolute {
	@include media-breakpoint-up(xl) {
		img {
			max-width: 971px;
		}
		position: absolute;
		right: -13px;
		text-align: right;
	}
}

.intro-price {
	.badge {
		border-bottom-left-radius: 0;
	}

	.high-light {
		color: #f9e8b5;

		&::after {
			background-color: rgba(57,180,146,.13);
		}
	}

	.icon {
		font-size: 11px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 27px;
		height: 27px;
		color: $white;
		background-color: #367E62;
		border-radius: 50%;
	}
}