.slick-slider {
	margin-left: -15px;
	margin-right: -15px;

	.box {
		background-color: transparent !important;
		font-size: $font-size-base;

		&:focus {
			outline: none;
		}
	}

	img {
		max-width: 100%;
		width: 100%;
	}

	:focus {
		outline: none;
	}

}

.custom-arrows {
	.slick-arrow {
		display: none !important;
		border: 0;

		i {
			font-size: 40px;
		}

		&:hover {
			box-shadow: none;
		}
	}

	@include media-breakpoint-up(lg) {
		.slick-arrow {
			display: block !important;
		}
	}
}

.slick-slide {
	height: inherit;
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
}

.slick-track {
	display: flex;
}


.slick-vertical {
	height: auto;

	.slick-track {
		display: block;
	}

	.slick-dots {
		right: 50px;
		left: auto;
		top: 50%;
		flex-flow: column;
		align-items: flex-end;
		transform: translateY(-50%);
	}
}

.slick-arrow {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: $white;
	width: 45px;
	height: 45px;
	font-size: 14px;
	border-radius: 50%;
	color: $primary;
	border: 1px solid #C4C4C4;
	z-index: 100;
	transition: all 0.3s;

	&:hover {
		color: $primary;
		border-color: transparent;
		box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	}

	&.slick-disabled {
		cursor: no-drop;
	}

	&.slick-prev {
		left: -60px;
		right: auto;
	}

	&.slick-next {
		left: auto;
		right: -60px;
	}

	i {
		line-height: inherit;
		font-weight: 400;
	}

	cursor: pointer;
}

.custom-arrows-02-prev,
.custom-arrows-02-next {
	transform: none;
}

.custom-arrows-03 {
	.slick-prev {
		left: 35px;
	}

	.slick-next {
		right: 35px;
	}
}

.slick-dots {
	display: flex !important;
	justify-content: center;
	align-items: center;
	list-style: none;
	padding: 0;
	margin-bottom: 0;
	margin-top: 30px;

	li {
		cursor: pointer;
		text-align: center;
		margin: 0 10px;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			display: inline-block;
			line-height: 8px;
			width: 8px;
			height: 8px;
			background: $primary;
			border-radius: 50%;
			transition: all .1s linear;
		}

		&:hover {
			span {
				transform: scale3d(1.5, 1.5, 1.5);
			}
		}
	}


}

.slider .slick-dots {
	position: absolute;
	bottom: 30px;
	left: 0;
	right: 0;
}

.slick-dots li.slick-active,
.slick-dots li:hover,
.slick-arrow:active,
.slick-dots li:active,
.slick-arrow:focus,
.slick-dots li:focus {
	opacity: 1;
}

.slick-dots li.slick-active {
	width: 26px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: 2px solid $primary;
}

.slick-dots-light {
	.slick-dots li span {
		background: $white;
	}

	.slick-dots li.slick-active {
		border: 2px solid $white;
	}

	.slick-dots li.slick-active span {
		background: $white;
	}
}


.custom-slider-03 {
	.slick-prev {
		left: 15px;
		margin-left: 22.5px;
	}

	.slick-next {
		right: 15px;
		margin-right: 22.5px;
	}

	@include media-breakpoint-up(sm) {
		.slick-prev {
			left: calc((100vw / 2 - 546px / 2));
		}

		.slick-next {
			right: calc((100vw / 2 - 546px / 2));
		}
	}
	@include media-breakpoint-up(md) {
		.slick-prev {
			left: calc((100vw / 2 - 738px / 2));
		}

		.slick-next {
			right: calc((100vw / 2 - 738px / 2));
		}
	}
	@include media-breakpoint-up(lg) {
		.slick-prev {
			left: calc((100vw / 2 - 962px / 2));
		}

		.slick-next {
			right: calc((100vw / 2 - 962px / 2));
		}
	}
	@media (min-width: 1200px) {
		.slick-prev {
			left: calc(100vw / 2 - 1200px / 2);
		}

		.slick-next {
			right: calc(100vw / 2 - 1200px / 2);
		}
	}


	.slick-dots {
		font-size: 18px;
		color: $primary;
		margin-top: 25px;

		.dot-divider,
		.dot-text,
		.dot {
			height: auto;
			width: auto;
			background-color: transparent;
			border: none;
			display: inline-block;
		}

		li:not(.slick-active) {
			display: none;
		}

		.dot-divider {
			margin: 0 8px;
		}

		.slick-active {
			border: none;
		}
	}

	.slick-slide:not(.slick-active) {
		opacity: 0.4;
	}
}

.custom-dots-01 .slick-dots {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 43px;
	flex-flow: column;
	margin-top: 0;

	li:not(:last-child) {
		margin-bottom: 20px;
	}

}

.custom-slider-01 {
	.slick-dots {
		position: absolute;
		right: 47px;
		top: -65px;
	}

	.slick-arrow {
		top: -43px;
		transform: translateY(0);
		border: none !important;
		background: none !important;

		&.slick-next {
			right: 0;
		}

		&.slick-prev {
			left: auto;
			right: 11%;
		}
	}

	.slick-list {
		@include media-breakpoint-up(xl) {
			margin-right: -300px;
		}
	}
}

.custom-slider-04 {
	.slick-arrow {
		top: -120px;
		transform: translateY(0);
		background: none !important;

		&.slick-next {
			right: 12.5px;
		}

		&.slick-prev {
			left: auto;
			right: 67px;
		}
	}

	.slick-list {
		@include media-breakpoint-up(xxl) {
			margin-right: -480px;
		}
	}
}

.dots-inner {
	.slick-dots {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 35px;
	}
}

.custom-slider-opacity {
	.slick-slide:not(.slick-current) {
		opacity: 0.5;
	}
}

.dots-mb-mt-0 {
	@include media-breakpoint-down(md) {
		.slick-dots {
			margin-top: 0;
		}

	}
}

.slider-home-mobile {
	.card-img-top {
		height: 386px;
	}

	.slick-dots {
		margin-bottom: 30px;
	}
}

.custom-slick-bx-01 {
	@include media-breakpoint-up(lg){
		.slick-slide:not(.slick-active) .card:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: #F8F8F8;
			opacity: 0.4;
			cursor: not-allowed;
			z-index: 2;
		}

		.slick-list {
			padding-left: calc((100% - 1200px) / 2);
			padding-right: calc((100% - 1200px) / 2);
		}
	}

}
.custom-arrow-01 {
	.slick-arrow {
		border: 0;
		color: $primary;
		top: auto;
		bottom: -10px;
		transform: none;
		background-color: transparent;

		&.slick-next {
			left: 41%;

			i:before {
				content: "\f060";
			}
		}

		&.slick-prev {
			right: 41%;
			left: auto;

			i:before {
				content: "\f061";
			}
		}

		&:hover {
			box-shadow: none;
		}

		i {
			font-size: 14px;
			font-weight: 900;
		}
	}
}


