@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		.col#{$infix}-custom {
			flex: 0 0 20%;
			max-width: 20%;
		}
		.vh#{$infix}-100 {
			height: 100vh !important;
		}

	}

}

// Font sizes
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@each $font-size, $value in $font-sises {
			.fs#{$infix}-#{$font-size} {
				font-size: $value !important;
			}
		}

	}
}

//Box Shadow
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@each $label, $value in $box-shadows {
			.shadow#{$infix}-#{$label} {
				box-shadow: $value !important;
			}
			.shadow-hover#{$infix}-#{$label} {
				&:hover {
					box-shadow: $value !important;
				}
			}
		}
	}
}

.shadow-sm {
	box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.08) !important;
}

@each $label, $value in $line-heights {
	.lh-#{$label} {
		line-height: $value !important;
	}
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@if $infix != '' {
			.w#{$infix}-100 {
				width: 100% !important;
			}
			.w#{$infix}-auto {
				width: auto !important;
			}
		}
		.border#{$infix}-0 {
			border: none !important;
		}
		.shadow#{$infix}-none {
			box-shadow: none !important;
		}
		.border#{$infix} {
			border: $border-width solid $border-color !important;
		}
		.border#{$infix}-top {
			border-top: $border-width solid $border-color !important;
		}
		.border#{$infix}-right {
			border-right: $border-width solid $border-color !important;
		}
		.border#{$infix}-bottom {
			border-bottom: $border-width solid $border-color !important;
		}
		.border#{$infix}-left {
			border-left: $border-width solid $border-color !important;
		}

		.border#{$infix}-top-0 {
			border-top: 0 !important;
		}
		.border#{$infix}-right-0 {
			border-right: 0 !important;
		}
		.border#{$infix}-bottom-0 {
			border-bottom: 0 !important;
		}
		.border#{$infix}-left-0 {
			border-left: 0 !important;
		}
	}
}

//Opacity
@each $label, $value in $opacity {
	.opacity-#{$label} {
		opacity: $value !important;
	}
	.opacity-hover-#{$label} {
		&:hover {
			opacity: $value !important;
		}
	}
}

//z-index
@each $label, $value in $z-index {
	.z-index-#{$label} {
		z-index: $value !important;
	}

}

@each $label, $value in $letter-spacings {
	.letter-spacing-#{$label} {
		letter-spacing: $value !important;
	}
}


.height-60 {
	height: 60px;
}

// Font border width
@for $i from 1 through 6 {
	.border-#{$i}x {
		border-width: ($i * 1px) !important;
	}
}

.bg-gray {
	background-color: $gray;
}

.bg-gray-1 {
	background-color: #F7F7F7 !important;
}

.bg-gray-2 {
	background-color: #F5F5F5 !important;
}

.bg-gray-3 {
	background-color: #EFEDED !important;
}

.bg-gray-4 {
	background-color: #BBBBBB !important;
}

.bg-gray-5 {
	background-color: #F8F8F8 !important;
}

.bg-brand-2 {
	background-color: #FDC040 !important;
}

.border-input {
	border-color: $input-border-color !important;
}

.border-custom {
	border-color: #DEDEDE !important;
}

.bg-cover {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.bg-img-cover-center {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.overflow-y-auto {
	overflow-y: auto !important;
}

.font-weight-300 {
	font-weight: $font-weight-300 !important;
}


.font-weight-500 {
	font-weight: $font-weight-500 !important;
}

.font-weight-600 {
	font-weight: $font-weight-600 !important;
}

.font-weight-700 {
	font-weight: 700 !important;
}

.font-weight-800 {
	font-weight: $font-weight-800 !important;
}

.left-15 {
	left: 15px !important;
}


.list-group-no-border {
	.list-group-item {
		border: none !important;
	}

}

.hover-transform-20 {
	transform: translateY(0);
	transition: .3s;

	&:hover {
		transform: translateY(-10px);
		filter: drop-shadow(0 0 8px rgba(0, 0, 0, .08));
	}
}

.bg-color-1 {
	background-color: $background-color-1 !important;
}

.text-gray {
	color: #959595 !important;
}

.text-gray-02 {
	color: #B3B3B3 !important;
}

.text-gray-03 {
	color: #999999 !important;
}

.text-gray-04 {
	color: #838383 !important;
}

.hover-zoom-in {
	overflow: hidden;

	img, .img, .card-img {
		transform: scale(1);
		transition: 0.2s ease-in-out;
	}

	&:hover {
		img, .img, .card-img {
			transform: scale(1.04);
		}
	}
}

.hover-zoom-in-custom {
	.card-img {
		transform: scale(1) translateZ(0);
		transition: all 1.5s cubic-bezier(.19, 1, .22, 1);
		overflow: hidden;

		img {
			transform: scale(1.13) translateZ(0);
			transition: all 1.5s cubic-bezier(.19, 1, .22, 1);

		}
	}

	&:hover {
		.card-img {
			transform: scale(1.08) translateZ(0);

			img {
				transform: scale(1) translateZ(0);
			}
		}
	}
}

.hover-zoom-in-custom-2 {
	.card-img {
		transform: scale(1) translateZ(0);
		transition: all 1.5s cubic-bezier(.19, 1, .22, 1);
		overflow: hidden;

		img {
			transform: scale(1.13) translateZ(0);
			transition: all 1.5s cubic-bezier(.19, 1, .22, 1);

		}
	}

	&:hover {
		.card-img {
			transform: scale(1.2) translateZ(0);

			img {
				transform: scale(1) translateZ(0);
			}
		}
	}
}

.hover-opacity {
	overflow: hidden;

	img, .img, .card-img {
		opacity: 1;
		transition: 1s ease-in-out;
	}

	&:hover {
		img, .img, .card-img {
			opacity: .5;
		}
	}
}

.hover-change-button {
	.button-change {
		transition: all 0.5s;
	}

	&:hover {
		.button-change {
			background-color: $primary !important;
			color: $white !important;
		}
	}
}

.hover-change-content {
	.content-change {
		opacity: 0;
		transition: all 0.5s;
	}

	&:hover {
		.content-change {
			opacity: 1;
		}
	}
}

.hover-text-body {
	&:hover {
		color: $body-color !important;
	}

}


.hover-shine {
	position: relative;
	overflow: hidden;

	&::before {
		position: absolute;
		top: 0;
		left: -100%;
		z-index: 2;
		display: block;
		content: '';
		width: 50%;
		height: 100%;
		background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
		transform: skewX(-25deg);

	}

	&:hover {
		&::before {
			animation: shine 1s;
		}
	}
}

@keyframes shine {
	100% {
		left: 125%;
	}
}

.hover-flash:hover {
	img, .card-img, .img {
		opacity: 1;
		animation: g5-flash 1.5s;
	}
}

@keyframes g5-flash {
	0% {
		opacity: .4;
	}
	100% {
		opacity: 1;
	}
}

.d-grid {
	display: grid !important;
}

.grid-item {
	display: grid;
	width: 100%;
}

.grid-gap {
	grid-column-gap: 30px !important;
	grid-row-gap: 30px !important;
}

.ratio {
	height: 100%;
	display: inline-block;
	overflow: hidden;
	vertical-align: middle;
	text-align: center;
	width: 100%;

	&::before {
		content: "";
		display: block;
	}
}


@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@each $grid-column, $value in $grid-number-columns {
			.g#{$infix}-#{$grid-column} {
				grid-template-columns: repeat($value, 1fr)
			}
		}
		@each $grid-column, $value in $grid-number-columns {
			.gc#{$infix}-#{$grid-column} {
				grid-column: span $value !important;
			}
		}
		@each $grid-column, $value in $grid-number-columns {
			.gc-start#{$infix}-#{$grid-column} {
				grid-column-start: $value !important;
			}
		}
		@each $grid-column, $value in $grid-number-columns {
			.gc-end#{$infix}-#{$grid-column} {
				grid-column-end: $value !important;
			}
		}
		@each $grid-row, $value in $grid-number-rows {
			.gr#{$infix}-#{$grid-row} {
				grid-row: span $value !important;
			}
		}
		.ratio#{$infix}-1-1 {
			&::before {
				padding-top: 100%;
			}
		}
		.ratio#{$infix}-1-2 {
			&::before {
				padding-top: 50%;
			}
		}
		.ratio#{$infix}-2-1 {
			&::before {
				padding-top: 150%;
			}
		}
		.ratio#{$infix}-3-2 {
			&::before {
				padding-top: 66.67%;
			}
		}
		.ratio#{$infix}-16-9 {
			&::before {
				padding-top: 31.58%;
			}
		}
		.position-absolute#{$infix} {
			position: absolute !important;
		}

		.flex#{$infix}-unset {
			flex: unset;
		}

	}
}

.text-active-primary.active,
.text-active-primary:hover.active,
.text-active-primary:focus.active,
.text-active-primary:hover {
	color: $primary !important;
}

.font-weight-active-400.active,
.font-weight-active-400:hover.active,
.font-weight-active-400:focus.active,
.font-weight-active-400-primary:hover {
	font-weight: 400 !important;
}

.border-active-primary.active,
.border-active-primary:hover.active,
.border-active-primary:focus.active,
.border-active-primary:hover {
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-color: $primary !important;
}

.dropdown-menu-xl {
	min-width: 32rem !important;
}

.container-xxl {
	@media screen and (min-width: 1440px) {
		max-width: 1440px;
	}
	@media screen and (min-width: 1650px) {
		max-width: 1650px;
	}
}

.container-3xl {
	@media screen and (min-width: 1200px) {
		max-width: 1920px;
	}
}

.container-xl {
	@media screen and (min-width: 1440px) {
		max-width: 1310px;
	}
}

.container-custom {
	@media screen and (min-width: 1440px) {
		max-width: 1440px;
	}
}


//Hover Color
@each $label, $value in $theme-colors {
	.hover-#{$label} {
		&:hover {
			color: $value !important;
		}

	}
}

@each $label, $value in $theme-colors {
	a.hover-#{$label} {
		&:hover {
			color: $value !important;
		}

	}
	a.hover-#{$label} {
		&:focus {
			color: $value !important;
		}

	}
}

//Hover Color
@each $label, $value in $theme-colors {
	.bg-hover-#{$label} {
		&:hover {
			background-color: $value !important;
		}

	}
}

@each $label, $value in $theme-colors {
	a.bg-hover-#{$label} {
		&:hover {
			background-color: $value !important;
		}

	}
	a.bg-hover-#{$label} {
		&:focus {
			background-color: $value !important;
		}

	}
}

@each $label, $value in $theme-colors {
	.border-#{$label} {
		border-color: $value !important;

	}
}

.border-light-dark {
	border-color: rgba(0, 0, 0, 0.25) !important;
}

.border-hover {
	&:hover {
		border: 1px solid $border-color !important;
	}
}

@each $label, $value in $theme-colors {
	.border-hover-#{$label} {
		&:hover {
			border-color: $value !important;
		}

	}
}

.pos-fixed-top {
	top: 0;
	left: 0;
}

.pos-fixed-top-center {
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

.pos-fixed-bottom-center {
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.pos-fixed-bottom-n12-center {
	bottom: -12px;
	left: 50%;
	transform: translateX(-50%);
}


.pos-fixed-top-right {
	top: 0;
	left: auto;
	right: 0;
}

.pos-fixed-bottom {
	bottom: 0;
	left: 0;
}

.pos-fixed-bottom-right {
	bottom: 0;
	right: 0;
}

.pos-fixed-center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.pos-fixed-left-center {
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.pos-fixed-right-center {
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}


.left-auto {
	left: auto !important;
}

.top-auto {
	top: auto !important;
}

.outline-none {
	outline: none !important;
}

.text-decoration-underline {
	text-decoration: underline !important;
}


.input-quality::-webkit-outer-spin-button,
.input-quality::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.input-quality[type=number] {
	-moz-appearance: textfield;
}

.number-cart::-webkit-inner-spin-button {
	display: none !important;
}

input.checkbox-primary[type="checkbox"] {
	color: $primary;
}

.bg-input {
	background-color: $input-bg !important;
}


.hover-change-image {
	.hover-image {
		transition: all 0.3s;
		opacity: 0;
	}

	.hover-bottom-to-top {
		transform: translateY(100%);
	}

	&:hover {
		.hover-image {
			opacity: 1 !important;
		}

		.hover-bottom-to-top {
			transform: translateY(0);
		}
	}
}

.custom-control-input:checked,
.custom-control-input:not(:disabled):active {
	~ .custom-control-label-secondary:before {
		border-color: $secondary;
		background-color: $secondary;
	}
}

.bg-video iframe {
	pointer-events: none;
	position: absolute;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%);
}

.text-line-through {
	text-decoration: line-through !important;
}

.primary-summary-inner.sticky, .form-review-inner.sticky {
	top: 110px !important;
}


.underline-primary {
	position: relative;
	z-index: 2;

	&:before {
		content: "";
		background: #D8E8DC;
		width: calc(100% + 4px);
		height: 9px;
		left: -2px;
		position: absolute;
		border-radius: 10px;
		bottom: 6px;
		z-index: -1;
	}
}


.h-16 {
	height: 16px !important;
}

.w-16 {
	width: 16px !important;
}


.h-40px {
	height: 40px !important;
}

.w-40px {
	width: 40px !important;
}

.w-43px {
	width: 43px !important;
}

.w-45px {
	width: 45px !important;
}

.h-45px {
	height: 45px !important;
}


.w-50 {
	width: 50% !important;
}

.w-sm-115 {
	@include media-breakpoint-up(sm) {
		width: 115px !important;
	}

}

.h-sm-115 {
	@include media-breakpoint-up(sm) {
		height: 115px !important;
	}

}

.w-28px {
	width: 28px !important;
}

.w-34px {
	width: 34px !important;
}

.h-34px {
	height: 34px !important;
}

.w-48px {
	width: 48px !important;
}

.w-50px {
	width: 50px !important;
}

.h-48px {
	height: 48px !important;
}

.h-50px {
	height: 50px !important;
}

.w-60px {
	width: 60px !important;
}

.w-70px {
	width: 70px !important;
}

.w-84px {
	width: 84px !important;
}

.w-90px {
	width: 90px !important;
}

.h-90 {
	height: 90px !important;
}


.w-100px {
	width: 100px !important;
}

.w-110px {
	width: 110px !important;
}

.w-150px {
	width: 150px;
}

.w-179px {
	width: 179px;
}

.mw-61px {
	max-width: 61px !important;
}

.mw-75px {
	max-width: 75px !important;
}

.mw-270 {
	width: 270px !important;
}

.mw-210 {
	width: 210px !important;
}


.w-235px {
	width: 235px !important;
}

.mw-102 {
	max-width: 102px !important;
}

.mw-460 {
	max-width: 460px !important;
}


.mw-196 {
	max-width: 196px !important;
}

.mw-320 {
	max-width: 320px !important;
}

.mw-393 {
	max-width: 393px !important;
}

.mw-370 {
	max-width: 370px !important;
}

.mw-400 {
	max-width: 400px !important;
}

.mw-430 {
	max-width: 430px !important;
}

.mw-450 {
	max-width: 450px !important;
}
.mw-480 {
	max-width: 480px !important;
}


.mw-500 {
	max-width: 500px !important;
}

.mw-630 {
	max-width: 630px !important;
}
.mw-670 {
	max-width: 670px !important;
}

.mw-750 {
	max-width: 750px !important;
}

.mw-730 {
	max-width: 730px !important;
}

.mw-830 {
	max-width: 830px !important;
}

.mw-896 {
	max-width: 896px !important;
}

.btn-link {
	font-weight: 600;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}


.form-control-01 input.form-control:focus {
	border: 1px solid $primary !important;
	background-color: #fff;
}

.btn-payment {
	box-shadow: none !important;

	&.active,
	&:hover {
		background-color: transparent !important;
		border-color: $primary !important;

		svg {
			color: $primary !important;
		}
	}
}

.paylay-box {
	display: none;
}

.custom-dropdown-toggle::after {
	position: absolute;
	right: 15px;
	content: '\f078';
	font-family: 'Font Awesome 5 Pro';
	font-size: 12px;
	border: 0;
	font-weight: bold;
}

.custom-dropdown-item {
	min-width: 100%;

	.dropdown-item {
		padding: 7.5px 15px;
	}
}

.custom-control-label:before {
	border-color: $primary;
	background: transparent;

}


@include media-breakpoint-down(md) {
	.collapse-tabs .tab-pane.fade {
		opacity: 1;
	}
}

.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}

.bg-green-light {
	background-color: rgba(59, 183, 126, 0.2) !important;
}

.text-green {
	color: #3BB77E !important
}

.bg-success-light {
	background-color: rgba(0, 181, 23, 0.2) !important
}

.bg-warning-light {
	background-color: rgba(253, 138, 20, 0.2) !important;
}

.bg-info-light {
	background-color: rgba(13, 202, 140, 0.2) !important;
}

.bg-gray-01 {
	background-color: #F5F5F5 !important;
}

.rounded-xl {
	border-radius: 10px !important;
}

.btn-xs {
	font-size: 11px;
	border-radius: 4px;
	display: inline-block;
	padding: 6px 19px;
	line-height: 1;
}

.bg-input {
	background-color: #f4f5f9 !important;
}

.border-gray {
	border-color: #f4f5f9 !important;
}

.border-gray-02 {
	border-color: #c0dbd9 !important;
}

.vh-75 {
	height: 75vh !important;
}

.text-color-subtext {
	color: #9a9a9a !important;
}

.text-custom-01 {
	color: #777777 !important;
}
