.dropright .dropdown-toggle::after,
.dropdown-toggle::after {
	content: "\f078";
	font-family: "Font Awesome 5 Pro";
	font-weight: 300;
	border: 0;
	vertical-align: 0;
	font-size: 12px;

}

.dropright {
	.dropdown-toggle::after {
		content: "\f105";
	}

	.dropdown-link {
		display: flex;

		&::after {
			margin-left: auto;
		}
	}
}

.dropdown-item a {
	color: inherit;
	text-decoration: none;
}

.dropdown-sm {
	min-width: 7rem !important;
}

.dropdown-menu-md {
	min-width: 20rem !important;
}

.dropdown-menu-lg {
	min-width: 30rem !important;
}

.dropdown-menu-xl {
	min-width: 40rem !important;
}

.dropdown-menu-product {
	min-width: 250px;
}

.dropdown-menu-xxl {
	min-width: 50rem;
}

.dropdown-menu-full {
	width: 100%;
}

.dropdown-header {
	display: inline-block;
	@include font-size($font-size-base);
}

.no-caret {
	.dropdown-toggle::after {
		display: none !important;
	}
}

.bootstrap-select {
	.dropdown-item.active {
		background-color: #ececec;
	}
}

.dropdown-product {
	.dropdown-toggle::after {
		font-size: 20px;
		font-weight: 500;
		margin-left: 20px;
	}
}
