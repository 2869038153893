
.banner-01{
	@include media-breakpoint-down(md){
		.card-img{
			height: 300px;
		}

	}
}
.banner-03 {
	.card-img {
		height: 200px;
	}

	@include media-breakpoint-up(sm) {
		.card-img {
			height: 240px;
		}

	}
}

.banner-04 {
	.card-img {
		padding-bottom: 120%;
	}

	@include media-breakpoint-up(lg) {
		.card-img {
			padding-bottom: 160.10144927536%;
		}
	}
}

.banner-05 {
	.card-img {
		height: 344px;
	}
}