// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-custom: 'Rubik', sans-serif;

$font_size-body: 1rem;

$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-800: 800;

$font-sises: (
		'4':4px,
		'11':11px,
		'12':12px,
		'13':13px,
		'14':14px,
		'15':15px,
		'16':16px,
		'18':18px,
		'20':20px,
		'22':22px,
		'24':24px,
		'26':26px,
		'28':28px,
		'30':30px,
		'32':32px,
		'33':33px,
		'34':34px,
		'36':36px,
		'37':37px,
		'40':40px,
		'42':42px,
		'48':48px,
		'50':50px,
		'56':56px,
		'58':58px,
		'60':60px,
		'64':64px,
		'70':70px,
		'99':99px,
		'100':100px,
		'120':120px,
);
$line-heights: (
		1:1,
		111:1.11,
		113:1.13,
		12:1.2,
		125:1.25,
		128:1.28,
		129:1.29,
		13:1.3,
		1357:1.357,
		14:1.4,
		1444:1.444,
		14375:1.4375,
		15:1.5,
		155:1.55,
		1625:1.625,
		166:1.66,
		178:1.78,
		1875:1.875,
		2:2,
		23:2.3,
);

$opacity: (
		0:0,
		1:0.1,
		2:0.2,
		3:0.3,
		4:0.4,
		5:0.5,
		6:0.6,
		7:0.7,
		8:0.8,
		9:0.9,
		10:1,
);
$z-index: (
		1:1,
		2:2,
		3:3,
		4:4,
		5:5,
		6:6,
		7:7,
		8:8,
		9:9,
		10:10,
);

$letter-spacings: (
		'0':0,
		'005':0.05em,
		'01':0.1em,
		'05px':0.5px,
		'07px':0.7px,
		'0458333px':0.458333px,
		'0545455px':0.545455px,
		'1px':1px,
		'375px':3.75px,
);

$heading-color: #000;


$grid-number-columns: (
		1:1,
		2:2,
		3:3,
		4:4,
		5:5,
		6:6,
		7:7,
		8:8,
		9:9,
		10:10,
		11:11,
		12:12,
		13:13,
		14:14,
		15:15,
		16:16,
		17:17,
		18:18,
		19:19,
		20:20,
);
$grid-number-rows: (
		1:1,
		2:2,
		3:3,
		4:4,
		5:5,
		6:6,
		7:7,
		8:8,
		9:9,
		10:10,
		11:11,
		12:12,
		13:13,
		14:14,
		15:15,
		16:16,
		17:17,
		18:18,
		19:19,
		20:20,
		21:21,
);

$box-shadow-1: 0px 4px 9px rgba(0, 0, 0, 0.05);
$box-shadow-2: 0 0 0 0.2rem #000;
$box-shadow-3: 0px 0px 15px rgba(0, 0, 0, 0.1);
$box-shadow-4: 0px 7px 45px rgba(0, 0, 0, 0.15);
$box-shadow-5: 0px 10px 30px rgba(0, 0, 0, 0.1);
$box-shadow-6: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
$box-shadow-7: 10px 24px 21px 0 rgba(0, 0, 0, 0.1);

$box-shadows: (
		'1':$box-shadow-1,
		'2':$box-shadow-2,
		'3':$box-shadow-3,
		'4':$box-shadow-4,
		'5':$box-shadow-5,
		'6':$box-shadow-6,
		'7':$box-shadow-7,
);

$background-color-1: #F0F3F2;

