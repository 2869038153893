.widget-instagram {
	.item {
		padding: 0 3px;
		margin-bottom: 6px;
	}
}

.widget-color {
	.item {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		position: relative;

		&:before {
			content: '';
			width: 32px;
			height: 32px;
			display: block;
			position: absolute;
			left: -4px;
			top: -4px;
			border-radius: 50%;
			opacity: 0;
			transform: scale(1.2);
			transition: all 0.3s linear;
			border: 1px solid $primary;
		}
	}

	li:hover .item:before {
		opacity: 1;
		transform: scale(1);
	}
}

.widget-category {
	.category-item {
		.number {
			opacity: 0;
			transition: all 0.2s;
		}

		&:hover {
			.number {
				opacity: 1;
			}
		}
	}
}
