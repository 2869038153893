
.product {
	img {
		transition: all 0.5s;
	}

	.image-hover {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
	}

	i:not(.fas) {
		font-weight: 400;
	}
	.tooltip {white-space: nowrap;}

	.add-to-cart {
		transition: color .4s .001s ease-out, background .4s .001s ease-out, visibility .4s .1s ease-out, opacity .4s .15s ease-out, transform .4s .1s ease-out;
	}

	.add-to-wishlist {
		transition: color .4s .001s ease-out, background .4s .001s ease-out, visibility .4s .1s ease-out, opacity .4s .15s ease-out, transform .4s .1s ease-out;
	}

	.add-to-compare {
		transition: color .4s .001s ease-out, background .4s .001s ease-out, visibility .4s .15s ease-out, opacity .4s .15s ease-out, transform .4s .15s ease-out;
	}

	.preview {
		transition: color .4s .001s ease-out, background .4s .001s ease-out, visibility .4s .25s ease-out, opacity .4s .25s ease-out, transform .4s .25s ease-out;
	}

	.content-change-vertical {
		a {
			transform: translateX(50%);
			opacity: 0;
		}

	}

	.content-change-horizontal {
		a {
			transform: translateY(50%);
			opacity: 0;
		}
	}

	.btn {
		transition: all .4s, transform .4s;
		opacity: 0;
		transform: translateY(15px);
	}

	&:hover {
		.image-active {
			opacity: 0;
		}

		.image-hover {
			opacity: 1;
		}

		.content-change-vertical {
			a {
				transform: translateX(0);
				opacity: 1;
			}

		}

		.content-change-horizontal {
			a {
				transform: translateY(0);
				opacity: 1;
			}
		}

		.btn {
			opacity: 1;
			transform: translateY(0);
		}
	}

	.progress-wrapper {
		margin: 2px;

		.progress {
			border-radius: 0;
			background-color: rgba(218, 63, 63, 0.19);
			height: 5px;
		}

		.progress-bar {
			background-color: #DA3F3F;
		}

	}

	.background-color-expand {
		background-color: #fff;
		border-radius: 5px;
		content: "";
		display: block;
		height: 100%;
		pointer-events: none;
		position: absolute;
		top: 0;
		transform: scale(1) translateY(0) translateZ(0);
		transition: transform .55s cubic-bezier(.2, .75, .5, 1);
		width: 100%;
		z-index: -1;

		&::before {
			background-color: transparent;
			box-shadow: 0 20px 30px #00000012;
			content: "";
			display: block;
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			transform-origin: center;
			transition: opacity .55s cubic-bezier(.2, .75, .5, 1);
			width: 100%;
			z-index: -2;
		}
	}

	&:hover {
		.background-color-expand {
			transform: scale(1.1) translateY(0) translateZ(0);

			&::before {
				opacity: 1;
			}
		}

	}

	.card-title a:hover {
		color: $body-color;
	}


}

.switch-layout {
	a {
		font-size: 9.5px;

		&.active {
			color: $white !important;
			background-color: $primary !important;
		}
	}
}

.flash-sale {
	background-color: #FFDB7D;
	padding: 4px 8px;
	font-weight: 500;
	border-radius: 3px;
	color: $primary;
}

.product-quick-view {
	max-width: 1040px;
	background-color: $white;
	margin: 0 auto;
}

.product-style-01 {
	.add-to-cart {
		border-radius: 5px 0 0 5px;
	}

	.preview {
		border-radius: 0 5px 5px 0;
	}
}